import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'

import iconTiktok from '@src/assets/images/icon-tiktok-black.svg'

import NewsLetter from './NewsLetter'

class Footer extends React.Component {
	constructor () {
		super()
		this.state = {
			loader: false
		}
	}

	scrollToTop = () => {
		$('html, body').animate({ scrollTop : 0 }, 800);
	}

	render() {
		const currentPath = this.props.location.pathname
		return (
			<footer className={((['/about', '/contact', '/why-sizigi', '/pricing', '/student-pricing', '/partnership', '/media', '/privacy', '/terms'].indexOf(currentPath) != -1) ? 'bg-white' : 'bg-gray') + (currentPath === '/pricing' ? " pricing-footer" : "")}>
				<div className="container">
					<div className="footer-top">
						<div className="row h-100">
							<div className="col-lg-5 col-md-5 d-flex flex-column">
								<NewsLetter className="pb-5" />
								<div className="mt-auto pt-4 pb-5 pb-md-0">
									<Link to="/"><img src={process.env.PUBLIC_URL + '/img/logo-2.png'} alt="Logo" className="logo" /></Link>
								</div>
							</div>
							<div className="col-lg-7 col-md-7">
								<div className="d-flex pl-0 pl-lg-4">
									<div className="clearfix nav-links">
										<div className="py-3"><Link to="/about" className="nav-item">About</Link></div>
										<div className="py-3"><Link to="/contact" className="nav-item">Contact</Link></div>
										<div className="py-3"><a href="https://www.linkedin.com/jobs/search/?f_C=28395120&geoId=92000000" target="_blank" rel="noopener noreferrer" className="nav-item">Careers</a></div>
										{/*<div className="py-3"><Link to="/partnership" className="nav-item">Partnership</Link></div>*/}
										<div className="py-3 d-block d-md-none"><Link to="/media" className="nav-item">Media</Link></div>
										<span id='extole_zone_global_footer' className="d-block d-md-none"></span>
										<div className="py-3 d-block d-md-none"><Link to="/terms" className="nav-item">Terms</Link></div>
									</div>
									<div className="clearfix nav-links">
										<div className="py-3"><Link to="/pricing" className="nav-item">Pricing</Link></div>
										<div className="py-3"><Link to="/faq" className="nav-item">FAQ</Link></div>
										<div className="py-3"><Link to="/privacy" className="nav-item">Privacy</Link></div>
										<div className="py-3 d-block d-md-none"><Link to="/partnership" className="nav-item">Partnership</Link></div>
									</div>
									<div className="clearfix nav-links d-none d-md-block">
										<div className="py-3"><Link to="/partnership" className="nav-item">Partnership</Link></div>
										{/*<div className="py-3"><Link to="/partner-dice" className="nav-item">Dice Affiliate</Link></div>*/}
										<div className="py-3"><Link to="/media" className="nav-item">Media</Link></div>
										<div className="py-3"><Link to="/terms" className="nav-item">Terms</Link></div>
									</div>
								</div>
								<span id='extole_zone_global_footer' className="d-none d-md-block"></span>
							</div>
						</div>
						<div className="py-5 mt-1 d-block d-md-none">
							<div className="mobile-social-block d-flex align-items-center">
								<a target="_blank" href="https://www.facebook.com/joinsizigi" rel="noopener noreferrer" className="text-black px-4 fs-28"><i className="fa fa-facebook"></i></a>
								<a target="_blank" href="https://www.instagram.com/joinsizigi/" rel="noopener noreferrer" className="text-black px-4 fs-28"><i className="fa fa-instagram"></i></a>
								<a target="_blank" href="https://twitter.com/joinsizigi" rel="noopener noreferrer" className="text-black px-4 fs-28"><i className="fa fa-twitter"></i></a>
								<a target="_blank" href="https://www.linkedin.com/company/joinsizigi/" rel="noopener noreferrer" className="text-black px-4 fs-28"><i className="fa fa-linkedin"></i></a>
								<a target="_blank" href="https://www.youtube.com/channel/UCpXSwoVyOQQGzOYR8-Hccaw/" rel="noopener noreferrer" className="text-black px-4 fs-28"><i className="fa fa-youtube-play"></i></a>
								<a target="_blank" href="https://www.tiktok.com/@sizigi?_d=secCgsIARCbDRgBIAIoARI%2BCjw09miBJQI9tlb49muDZb9bi9I8rk1UmEhE9Eem3TTc6UshqH7AD1hE4%2FGD7OhiuaF%2B66XcG5LLS6SmFQkaAA%3D%3D&language=en&preview_pb=0&share_item_id=6859757201637051654&timestamp=1597164086&tt_from=copy&u_code=d8g6c0707d1ehe&user_id=6743304853538571270&utm_campaign=client_share&utm_medium=ios&utm_source=copy&source=h5_m" rel="noopener noreferrer" className="pl-4">
									<img src={iconTiktok} alt="tiktok" className="img-tiktok" />
								</a>
							</div>
						</div>
					</div>
					<div className="footer-bottom d-flex justify-content-md-start justify-content-center">
						<div className="text-md-center">Sizigi &copy; {new Date().getFullYear()} all rights reserved</div>
						<div className="ml-auto d-none d-md-block social-icon-wrapper">
							<div className="d-flex align-items-center">
								<a target="_blank" href="https://www.facebook.com/joinsizigi" rel="noopener noreferrer" className="text-black social-icon"><i className="fa fa-facebook"></i></a>
								<a target="_blank" href="https://www.instagram.com/joinsizigi/" rel="noopener noreferrer" className="text-black social-icon"><i className="fa fa-instagram"></i></a>
								<a target="_blank" href="https://twitter.com/joinsizigi" rel="noopener noreferrer" className="text-black social-icon"><i className="fa fa-twitter"></i></a>
								<a target="_blank" href="https://www.linkedin.com/company/joinsizigi/" rel="noopener noreferrer" className="text-black social-icon"><i className="fa fa-linkedin"></i></a>
								<a target="_blank" href="https://www.youtube.com/channel/UCpXSwoVyOQQGzOYR8-Hccaw/" rel="noopener noreferrer" className="text-black social-icon"><i className="fa fa-youtube-play"></i></a>
								<a target="_blank" href="https://www.tiktok.com/@sizigi?_d=secCgsIARCbDRgBIAIoARI%2BCjw09miBJQI9tlb49muDZb9bi9I8rk1UmEhE9Eem3TTc6UshqH7AD1hE4%2FGD7OhiuaF%2B66XcG5LLS6SmFQkaAA%3D%3D&language=en&preview_pb=0&share_item_id=6859757201637051654&timestamp=1597164086&tt_from=copy&u_code=d8g6c0707d1ehe&user_id=6743304853538571270&utm_campaign=client_share&utm_medium=ios&utm_source=copy&source=h5_m" rel="noopener noreferrer" className="tiktok-icon-wrapper">
									<div className="tiktok-icon"></div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer
