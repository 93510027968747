import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import ecommerceSaga from '@src/redux/ecommerce/saga'
import contactUsRequests from '@src/redux/contactUsRequests/sagas';
import images from '@src/redux/images/sagas';
import blogs from '@src/redux/blogs/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    ecommerceSaga(),
    contactUsRequests(),
    images(),
    blogs()
  ])
}
