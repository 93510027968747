import React from 'react'
import { Link } from 'react-router-dom'
import { getUrl,getUrlfor } from '@src/lib/helpers/url_sync';
import cookie from 'js-cookie';

class Header extends React.Component {
	constructor() {
		super()
		this.state = {
			loader: false,
			menuOpen: false,
			token: false,
		}
	}

	componentDidMount() {
		const tokenVal = cookie.get('sizigi-token');
		if (tokenVal) {
			this.setState({ token: true });
		}
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.setState({ menuOpen: false })
		}
	}
	
	toggleMenu = (event) => {
		event.preventDefault()
		const { menuOpen } = this.state
		this.setState({ menuOpen: !menuOpen })
	}

	render() {
		const { menuOpen } = this.state;
		const { token } = this.state;
		const currentPath = this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
		const loginUrl = getUrl('login')
		const signupUrl = getUrl('signup')
		const foremployersUrl = getUrlfor('signin')

		if (window.location.href.indexOf("-btc") > -1) {
			document.querySelector('body').classList.add('login_header');
		} else{
			document.querySelector('body').classList.remove('login_header');
		}

		if ((window.location.pathname == '/postajob') || (window.location.pathname == '/Authentication') || (window.location.pathname == '/ConfirmMail') || (window.location.pathname == '/login') || (window.location.pathname == '/ForgetPassword') || (window.location.pathname == '/CreatePassword') || (window.location.pathname == '/general-info') || (window.location.pathname == '/AboutCompany') || (window.location.pathname == '/ConfirmMailUpdate') || (window.location.pathname == '/AuthenticationResent')) {
			document.querySelector('body').classList.add('onboard_header');
		} else{
			document.querySelector('body').classList.remove('onboard_header');
		}
		
		//
		return (
			<header className="position-relative bg-white">
				<div className="container position-relative">
					<div className="d-flex justify-content-center align-items-center">
						<div className="logo">
							<Link to="/"><img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="Logo" className="logo" /></Link>
						</div>
						<div className="nav-links d-none d-lg-block">
							{/* <Link to="/why-sizigi" className={`nav-item ${currentPath === '/why-sizigi' && `active`}`}>Why Sizigi?</Link> */}
							{/* <Link to="/how-it-works" className={`nav-item ${currentPath === '/how-it-works' && `active`}`}>How it Works</Link> */}
							{/* <Link to="/partnership" className={`nav-item ${currentPath === '/partnership' && `active`}`}>Partnership</Link> */}
							{/* <Link to="/pricing" className={`nav-item ${(currentPath === '/pricing' || currentPath === '/student-pricing') && `active`}`}>Pricing</Link> */}
							{/* extole */}
							{/* <Link to="/joinnow" className={`nav-item ${(currentPath === '/joinnow' || currentPath === '/joinnow') && `active`}`}>Join Now</Link> */}
							{/* <span id='extole_zone_global_header' className="nav-item d-inline-block"><a href="#">Refer a Friend</a></span>
							<span id='extole_zone_overlay'></span> */}

							{/* {
								(window.location.pathname) == '/postajob' ? <> 
									<Link to="/search-jobs" className={`nav-item ${(currentPath === '/search-jobs' || currentPath === '/search-jobs') && `active`}`}>Search Jobs</Link>
									<Link to="/postajob" className={`nav-item ${(currentPath === '/postajob' || currentPath === '/postajob') && `active`}`}>For employers</Link>	
									<Link to="/login" className={`nav-item ${(currentPath === '/login' || currentPath === '/login') && `active`}`}>Log In </Link>
									<Link to="/postajob-btc" className={`nav-item ${(currentPath === '/postajob-btc' || currentPath === '/postajob-btc') && `active`}`}>Sign up</Link>
								</> :  <>
								<Link to="/search-jobs" className={`nav-item ${(currentPath === '/search-jobs' || currentPath === '/search-jobs') && `active`}`}>Search Jobs</Link>
								<Link to="/postajob" className={`nav-item ${(currentPath === '/postajob' || currentPath === '/postajob') && `active`}`}>For employers</Link>
								
							</>
							} */}
							
							{/* {
								(window.location.pathname) == '/postajob' ? '' : <>
								<Link to="/login-btc" className={`nav-item ${(currentPath === '/login-btc' || currentPath === '/login-btc') && `active`}`}>Log In </Link>
								<Link to="/postajob-btc" className={`nav-item ${(currentPath === '/postajob-btc' || currentPath === '/postajob-btc') && `active`}`}>Sign up</Link>
								</>
							} */}
							{/* <Link to="/postajob-btc" className={`nav-item ${(currentPath === '/postajob-btc' || currentPath === '/postajob-btc') && `active`}`}>Post a Job (B2C)</Link> */}
							
						</div>
						<div className="ml-auto">
							<div className="d-none d-lg-block">
								{
									token ? <>
										<a href={loginUrl ? loginUrl : ' #'} className="btn btn-mt-primary btn-mt-md btn-join">Go to profile</a>
									</> : <>
									<a href={foremployersUrl? foremployersUrl : '#'} className="btn btn-mt-primary btn-mt-md btn-join">For employers</a>
										<a href={signupUrl ? signupUrl : ' #'} className="btn btn-mt-primary btn-mt-md btn-join ml-4">Join Now</a>
										<a href={loginUrl ? loginUrl : ' #'} className="btn btn-mt-secondary btn-mt-md ml-4">Log in</a>
									</>
								}
							</div>
							<div className="d-block d-lg-none">
								<button className="btn text-black btn-menu" onClick={this.toggleMenu}>
									{menuOpen ? <i className="fa fa-close"></i> : <i className="fa fa-bars"></i>}
								</button>
							</div>
						</div>
					</div>
					{/* */}
					<div className={"menu-container d-block d-lg-none" + (menuOpen ? ' show-menu' : '')}>
						<div className="clearfix">
							<div className="nav-links pt-2">
								{/* <div className="py-3"><Link to="/why-sizigi" className={`nav-item ${currentPath === '/why-sizigi' && `active`}`}>Why Sizigi?</Link></div> */}
								<div className="py-3"><Link to="/how-it-works" className={`nav-item ${currentPath === '/how-it-works' && `active`}`}>How it Works</Link></div>
								{/* <div className="py-3"><Link to="/partnership" className={`nav-item ${currentPath === '/partnership' && `active`}`}>Partnership</Link></div> */}
								<div className="pt-1 pb-3"><Link to="/pricing" className={`nav-item ${currentPath === '/pricing' && `active`}`}>Pricing</Link></div>
								{
									token ? <>
										<div className="py-3 text-center"><a href={loginUrl ? loginUrl : ' #'} className="btn btn-mt-primary btn-mt-md">Go to profile</a></div>
									</> : <>
										<div className="py-3 text-center"><a href={signupUrl ? signupUrl : ' #'} className="btn btn-mt-primary btn-mt-md">Join Now</a></div>
										<div className="pt-1 pb-3 text-center"><a href={loginUrl ? loginUrl : ' #'} className="btn btn-mt-secondary btn-mt-md">Log in</a></div>
									</>
								}
								{/* extole */}
								<span id='extole_zone_global_header' className="nav-item d-inline-block pt-1 pb-3"></span>
								<span id='extole_zone_mobile_menu' className="d-block"></span>
							</div>
						</div>
					</div>
				</div>
			</header>
		)
	}
}

export default Header
