import React from 'react';
import ReactDOM from 'react-dom';
import App from './layout/App';
import * as serviceWorker from './serviceWorker';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js'
import 'font-awesome/css/font-awesome.min.css'
import 'toastr/build/toastr.min.css'

/* */
import '@src/admin/assets/plugins/medium-editor-insert-plugin/medium-editor-insert-plugin.min.css'
import '@src/admin/assets/plugins/react-medium-editor/default.css'
import '@src/admin/assets/plugins/react-medium-editor/medium-editor.css'
/* */

import './style'

window.jQuery = $;
window.$ = $;

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// const originalWarn = console.warn.bind(console.warn)
// beforeAll(() => {
//   console.warn = (msg) =>
//     !msg.toString().includes('componentWillReceiveProps') && originalWarn(msg)
// })
// afterAll(() => {
//   console.warn = originalWarn
// })
