// import React from 'react';
// import IntlMessages from '@src/admin/components/utility/intlMessages';
import Img1 from '@src/assets/images/1.jpg';
import Img2 from '@src/assets/images/2.jpg';
import Img3 from '@src/assets/images/3.jpg';
import Img4 from '@src/assets/images/4.jpg';
import Img5 from '@src/assets/images/5.jpg';
import Img6 from '@src/assets/images/6.jpg';
import Img7 from '@src/assets/images/7.jpg';

const images = [
  {
    id: 'a',
    timestamp: 811396800000,
    title: 'Netscape 2.0 ships introducing Javascript',
    img: Img1,
  },
  {
    id: 'b',
    timestamp: 1108702800000,
    title: 'Jesse James Garrett releases AJAX spec',
    img: Img2,
  },
  {
    id: 'c',
    timestamp: 1156564800000,
    title: 'jQuery 1.0 released',
    img: Img3,
  },
  {
    id: 'd',
    timestamp: 1256443200000,
    title: 'First underscore.js commit',
    img: Img4,
  },
  {
    id: 'e',
    timestamp: 1286942400000,
    title: 'Backbone.js becomes a thing',
    img: Img5,
  },

  {
    id: 'f',
    timestamp: 1331697600000,
    title: 'Angular 1.0 released',
    img: Img6,
  },
  {
    id: 'g',
    timestamp: 1369800000000,
    title: 'React is open-sourced; developers rejoice',
    img: Img7,
  },
];

export default images;
