import { all, takeEvery, put /*, call*/ } from 'redux-saga/effects';
import actions from './actions';
// import api from '@src/lib/services/api'
import fakeData from './fakeData';
const fakeDataList = new fakeData(1).getAll();

function* loadContactUsRequest() {
  try {
    // let data = yield call(api.fetchContactUsRequests);
    // yield put(actions.loadContactUsRequestsSuccess(data));
    yield put(actions.loadContactUsRequestsSuccess(fakeDataList));
  } catch (error) {
    yield put(actions.loadContactUsRequestsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CONTACT_US_REQUESTS, loadContactUsRequest)
  ]);
}
