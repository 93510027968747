const actions = {
  LOAD_BLOGS: 'LOAD_BLOGS',
  LOAD_BLOGS_SUCCESS: 'LOAD_BLOGS_SUCCESS',
  LOAD_BLOGS_ERROR: 'LOAD_BLOGS_ERROR',
  TOGGLE_BLOG_MODAL: 'TOGGLE_BLOG_MODAL',

  loadBlogs: () => {
    return { type: actions.LOAD_BLOGS };
  },

  loadBlogsSuccess: data => ({
    type: actions.LOAD_BLOGS_SUCCESS,
    payload: { data },
  }),

  loadBlogsError: error => ({
    type: actions.LOAD_BLOGS_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_BLOG_MODAL,
    payload: { data },
  })
};
export default actions;
