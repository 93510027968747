import axios from 'axios'
import { getToken, clearToken } from '@src/lib/helpers/utility';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({ forceRefresh: true });

const webAPIHost = process.env.REACT_APP_WEB_API_HOST || 'http://localhost:9005'

function getAPIHeader (token) {
	return {
		headers: {
			'authorization': token
		}
	}
}

function unauthorizedHandleFn () {
	clearToken()
	history.push('/admin/signin');
}

const Api = {
	// Auth
	login: async (data) => {
		const url = webAPIHost + '/authenticate';
		return axios.post(url, data).then((response) => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	logout: async () => {
		const url = webAPIHost + '/api/logout';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.get(url, options).then((response) => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	// Contact request
	// fetchContactUsRequests: (data, cb) => {
	// 	const url = webAPIHost + '/api/getContactUsRequests';
	// 	const token = getToken().get('idToken');
	// 	const options = getAPIHeader(token);
	// 	options.data = data
	// 	axios.post(url, options)
	//   	.then((result) => { cb(null, result) })
	//   	.catch((error) => { cb(error, null) })
	// },
	// SizigiUsers
	fetchSizigiUsers: (data, cb) => {
		const host = process.env.REACT_APP_API_HOST
		axios.get(`${host}/profile/all?limit=${data.limit}&skip=${data.skip}&query=${data.searchText}&order=${data.sort}`, {
			headers: {
        Authorization: `Basic ${process.env.REACT_APP_API_TOKEN}`
      }
		})
		.then((result) => { cb(null, result) })
		.catch((error) => {
			cb(error, null)
		})
	},
	// Users
	fetchUsers: (data, cb) => {
		const url = webAPIHost + '/api/getUsers';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveUser: (data, cb) => {
		const url = webAPIHost + '/api/saveUser';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateUser: (data, cb) => {
		const url = webAPIHost + '/api/updateUser';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteUser: (id, cb) => {
		const url = webAPIHost + '/api/deleteUser/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Partners
	fetchPartners: (data, cb) => {
		const url = webAPIHost + '/api/getPartners';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	savePartner: (data, cb) => {
		const url = webAPIHost + '/api/savePartner';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updatePartner: (data, cb) => {
		const url = webAPIHost + '/api/updatePartner';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deletePartner: (id, cb) => {
		const url = webAPIHost + '/api/deletePartner/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Images
	fetchImages: (data, cb) => {
		const url = webAPIHost + '/api/getImages';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getImage: (data, cb) => {
		const url = webAPIHost + '/api/getImage';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	saveImage: (data, cb) => {
		const url = webAPIHost + '/api/saveImage';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateImage: (data, cb) => {
		const url = webAPIHost + '/api/updateImage';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteImage: (id, cb) => {
		const url = webAPIHost + '/api/deleteImage/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Blogs
	fetchBlogs: (data, cb) => {
		const url = webAPIHost + '/api/getBlogs';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getBlog: (data, cb) => {
		const url = webAPIHost + '/api/getBlog';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	saveBlog: (data, cb) => {
		const url = webAPIHost + '/api/saveBlog';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateBlog: (data, cb) => {
		const url = webAPIHost + '/api/updateBlog';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteBlog: (id, cb) => {
		const url = webAPIHost + '/api/deleteBlog/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Podcast
	fetchPodcasts: (data, cb) => {
		const url = webAPIHost + '/api/getPodcasts';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getPodcast: (data, cb) => {
		const url = webAPIHost + '/api/getPodcast';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	savePodcast: (data, cb) => {
		const url = webAPIHost + '/api/savePodcast';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updatePodcast: (data, cb) => {
		const url = webAPIHost + '/api/updatePodcast';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deletePodcast: (id, cb) => {
		const url = webAPIHost + '/api/deletePodcast/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Testimonials
	fetchTestimonials: (data, cb) => {
		const url = webAPIHost + '/api/getTestimonials';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveTestimonial: (data, cb) => {
		const url = webAPIHost + '/api/saveTestimonial';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateTestimonial: (data, cb) => {
		const url = webAPIHost + '/api/updateTestimonial';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteTestimonial: (id, cb) => {
		const url = webAPIHost + '/api/deleteTestimonial/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// SeoTags
	fetchSeoTags: (data, cb) => {
		const url = webAPIHost + '/api/getSeoTags';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getSeoTag: (data, cb) => {
		const url = webAPIHost + '/api/getSeoTag';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	saveSeoTag: (data, cb) => {
		const url = webAPIHost + '/api/saveSeoTag';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateSeoTag: (data, cb) => {
		const url = webAPIHost + '/api/updateSeoTag';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteSeoTag: (id, cb) => {
		const url = webAPIHost + '/api/deleteSeoTag/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Videos
	getTutorialVideos: (data, cb) => {
		const host = process.env.REACT_APP_API_HOST
		const url = host + '/admin/content/learning-center-tutorials';
		const token = `Basic ${process.env.REACT_APP_VIDEOS_TOKEN}`;
		const options = getAPIHeader(token);
		axios.get(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getInspirationalVideos: (data, cb) => {
		const host = process.env.REACT_APP_API_HOST
		const url = host + '/admin/content/learning-center-inspirational';
		const token = `Basic ${process.env.REACT_APP_VIDEOS_TOKEN}`;
		const options = getAPIHeader(token);
		axios.get(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getLinkData: async (data, cb) => {
		const url = webAPIHost + '/api/getLinkData';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		await axios.get(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveTutorialVideos: (data, cb) => {
		const host = process.env.REACT_APP_API_HOST
		const url = host + '/admin/content/learning-center-tutorials';
		const token = `Basic ${process.env.REACT_APP_VIDEOS_TOKEN}`;
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveInspirationalVideos: (data, cb) => {
		const host = process.env.REACT_APP_API_HOST
		const url = host + '/admin/content/learning-center-inspirational';
		const token = `Basic ${process.env.REACT_APP_VIDEOS_TOKEN}`;
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateVideo: (data, cb) => {
		const url = webAPIHost + '/api/updateVideo';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateVideoOrders: (data, cb) => {
		const url = webAPIHost + '/api/updateVideoOrders';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteVideo: (id, cb) => {
		const url = webAPIHost + '/api/deleteVideo/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Get Data
	webTestimonials: (data, cb) => {
		const url = webAPIHost + '/public/getTestimonials';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webPartners: (data, cb) => {
		const url = webAPIHost + '/public/getPartners';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webImages: (data, cb) => {
		const url = webAPIHost + '/public/getImages';
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webMedia: (data, cb) => {
		const url = webAPIHost + '/public/getMedia';
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webBlog: (data, cb) => {
		const url = webAPIHost + '/public/getBlog';
		const options = {}
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => { cb(error, null) })
		} catch (e) {
			cb(e, null)
		}
	},
	webPodcast: (data, cb) => {
		const url = webAPIHost + '/public/getPodcast';
		const options = {}
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => { cb(error, null) })
		} catch (e) {
			cb(e, null)
		}
	},
	webSeoTags: (data, cb) => {
		const url = webAPIHost + '/public/getSeoTags';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	addContact: (data, cb) => {
		const url = webAPIHost + '/public/addContactToHubspotAccount';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	addSignup: (data, cb) => {
		const url = 'https://applicant.joinsizigi.com/employer/signup/local';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	getPlanIds: (limit, sort) => {
		const url = webAPIHost + `/companyPlans?rql=limit${limit},sort${sort}`;
		const options = {}
		// if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) =>result)
			.catch((error) => error)
		} catch (error) {
			console.log('error', error);
		}
	}
}

export default Api;
