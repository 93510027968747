const actions = {
  LOAD_CONTACT_US_REQUESTS: 'LOAD_CONTACT_US_REQUESTS',
  LOAD_CONTACT_US_REQUESTS_SUCCESS: 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_CONTACT_US_REQUESTS_ERROR: 'LOAD_FROM_FIRESTORE_ERROR',

  loadContactUsRequests: () => {
    return { type: actions.LOAD_CONTACT_US_REQUESTS };
  },

  loadContactUsRequestsSuccess: data => ({
    type: actions.LOAD_CONTACT_US_REQUESTS_SUCCESS,
    payload: { data },
  }),

  loadContactUsRequestsError: error => ({
    type: actions.LOAD_CONTACT_US_REQUESTS_ERROR,
    payload: { error },
  })
};
export default actions;
