import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  images: {},
  modalActive: false,
  image: {
    key: null,
    id: new Date().getTime(),
    title: '',
    slug: '',
    excerpt: '',
    status: 'draft', // publish
    description: '',
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_IMAGES:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        images: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_IMAGE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        image: payload.data == null ? initState.image : payload.data,
      };
    default:
      return state;
  }
}
