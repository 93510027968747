import React from 'react'
import Boot from '@src/redux/boot'
import { useSelector, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import useWindowSize from '@src/lib/hooks/useWindowSize'
import appActions from '@src/redux/app/actions'
import siteConfig from '@src/config/site.config'
import Sidebar from '@src/admin/containers/Sidebar/Sidebar'
import Topbar from '@src/admin/containers/Topbar/Topbar'
import GlobalStyles from '@src/admin/assets/styles/globalStyle'
import { DashboardContainer, DashboardGlobalStyles } from '@src/admin/containers/Dashboard.styles'

import '@src/admin/assets/styles/common.css'

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const styles = {
  layout: { flexDirection: 'row', overflowX: 'hidden' },
  content: {
    padding: '70px 0 0',
    flexShrink: '0',
    background: '#f1f3f6',
    position: 'relative',
  },
  footer: {
    background: '#ffffff',
    textAlign: 'center',
    borderTop: '1px solid #ededed',
  },
};

function AdminLayout(props) {
  const dispatch = useDispatch();
  const appHeight = useSelector(state => state.App.height);
  const { width, height } = useWindowSize();

  React.useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);
  return (
    <DashboardContainer>
      <GlobalStyles />
      <DashboardGlobalStyles />
      <Layout style={{ height: height }}>
        <Topbar />
        <Layout style={styles.layout}>
          <Sidebar />
          <Layout className="isoContentMainLayout" style={{ height: appHeight }} >
            <Content className="isomorphicContent" style={styles.content}>
              {props.children}
            </Content>
            <Footer style={styles.footer}>{siteConfig.footerText}</Footer>
          </Layout>
        </Layout>
      </Layout>
    </DashboardContainer>
  );
}

// class AdminLayout extends React.Component {
// 	constructor () {
// 		super();
// 		this.state = {
// 			loader: false
// 		}
// 	}
//
// 	render () {
// 		return (
// 			<div id="adminMain">
// 				<div className="text-center">ADMIN</div>
// 				<div className="app-container">
// 				  <main className="app-main-content">
// 						{this.props.children}
// 					</main>
// 				</div>
// 			</div>
// 		)
// 	}
// }

Boot()
  .then(() => AdminLayout())
  .catch(error => console.error(error));

export default AdminLayout
