const options = [
  {
    key: 'sizigi-users',
    label: 'sidebar.sizigiUsers',
    leftIcon: 'ion-person',
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'ion-person',
  },
  {
    key: 'blogs',
    label: 'sidebar.blog',
    leftIcon: 'ion-ios-paper',
  },
  {
    key: 'podcasts',
    label: 'sidebar.podcast',
    leftIcon: 'ion-fireball',
  },
  {
    key: 'partners',
    label: 'sidebar.partners',
    leftIcon: 'ion-person',
  },
  // {
  //   key: 'contact-us-requests',
  //   label: 'sidebar.contactUsRequests',
  //   leftIcon: 'ion-person',
  // },
  {
    key: 'images',
    label: 'sidebar.images',
    leftIcon: 'ion-android-checkbox-outline',
  },
  {
    key: 'testimonials',
    label: 'sidebar.testimonials',
    leftIcon: 'ion-thumbsup',
  },
  {
    key: 'seo-tags',
    label: 'sidebar.seoTags',
    leftIcon: 'ion-arrow-graph-up-right',
  },
  {
    key: 'videos',
    label: 'sidebar.videos',
    leftIcon: 'ion-ios-videocam',
  },
];
export default options;
