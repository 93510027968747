import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  blogs: {},
  modalActive: false,
  blog: {
    key: null,
    id: new Date().getTime(),
    title: '',
    slug: '',
    excerpt: '',
    status: 'draft', // publish
    description: '',
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_BLOGS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_BLOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        blogs: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_BLOGS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_BLOG_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        blog: payload.data == null ? initState.blog : payload.data,
      };
    default:
      return state;
  }
}
