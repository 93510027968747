import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import AppProvider from "./AppProvider";
import ErrorBoundary from "./ErrorBoundary";
import { store } from "../redux/store";

import BlankLayout from "./BlankLayout";
import WebLayout from "./WebLayout";
import AdminLayout from "./AdminLayout";
//
import Api from "@src/lib/services/api";
import Loader from "@src/components/utility/loader";
import NotFound from "@src/views/NotFound";

const Home = lazy(() => import("@src/views/Home"));
const Partnership = lazy(() => import("@src/views/Partnership"));
const Work = lazy(() => import("@src/views/Work"));
const Pricing = lazy(() => import("@src/views/Pricing"));
const joinnow = lazy(() => import("@src/views/joinnow"));
// const postajob = lazy(() => import('@src/views/postajob'))
// const buildaddresume = lazy(() => import('@src/views/buildaddresume'))
// const AboutCompany = lazy(() => import('@src/views/AboutCompany'))
// const AboutCompanystepone = lazy(() => import('@src/views/AboutCompanystepone'))
// const HealthWellness = lazy(() => import('@src/views/health-and-wellness'))
// const login = lazy(() => import('@src/views/login'))
// const CreatePassword = lazy(() => import('@src/views/CreatePassword'))
const ForgetPassword = lazy(() => import("@src/views/ForgetPassword"));
const StudentPricing = lazy(() => import("@src/views/StudentPricing"));
const WhySizigi = lazy(() => import("@src/views/WhySizigi"));
const Contact = lazy(() => import("@src/views/Contact"));
const About = lazy(() => import("@src/views/About"));
const Media = lazy(() => import("@src/views/Media"));
const Blog = lazy(() => import("@src/views/Blog"));
const Podcast = lazy(() => import("@src/views/Podcast"));
const Privacy = lazy(() => import("@src/views/Privacy"));
const Terms = lazy(() => import("@src/views/Terms"));
const Faq = lazy(() => import("@src/views/Faq"));
const DiceAffiliate = lazy(() => import("@src/views/DiceAffiliate"));

const Authentication = lazy(() => import("@src/views/Authentication"));
const ConfirmMail = lazy(() => import("@src/views/ConfirmMail"));
const PostAJobBtc = lazy(() => import("@src/views/postajob-btc"));
const LoginBtc = lazy(() => import("@src/views/login-btc"));
const ForgotPasswordBtc = lazy(() => import("@src/views/ForgotPassword-btc"));
const CreatePasswordBtc = lazy(() => import("@src/views/CreatePassword-btc"));
const ConfirmMailBtc = lazy(() => import("@src/views/ConfirmMail-btc"));
const AuthenticationBtc = lazy(() => import("@src/views/Authentication-btc"));
const uploadcreate = lazy(() => import("@src/views/UploadCreateResume"));
const generalinfo = lazy(() => import("@src/views/general-info"));
const jobDetails = lazy(() => import("@src/views/job-details"));
const candidateScreening = lazy(() => import("@src/views/candidate-screening"));
const messages = lazy(() => import("@src/views/messages"));
const UploadResume = lazy(() => import("@src/views/uploadresume"));
const CreateResumeStepOne = lazy(() =>
  import("@src/views/CreateResumeStepOne")
);
const CreateResumeStepTwo = lazy(() =>
  import("@src/views/CreateResumeStepTwo")
);
const CreateResumeStepThree = lazy(() =>
  import("@src/views/CreateResumeStepThree")
);
const CreateResumeStepFour = lazy(() =>
  import("@src/views/CreateResumeStepFour")
);
const CreateResumeStepFive = lazy(() =>
  import("@src/views/CreateResumeStepFive")
);
const ScreeningQuestions = lazy(() => import("@src/views/ScreeningQuestions"));
const calendar = lazy(() => import("@src/views/calendar"));
const sharestory = lazy(() => import("@src/views/sharestory"));
const dashboard = lazy(() => import("@src/views/dashboard"));
const profilePersonalInfo = lazy(() =>
  import("@src/views/profile-personal-info")
);
const profileAccessSettings = lazy(() =>
  import("@src/views/profile-access-settings")
);
const profileChangePassword = lazy(() =>
  import("@src/views/profile-change-password")
);
const allNotifications = lazy(() => import("@src/views/all-notifications"));
const faqs = lazy(() => import("@src/views/faqs"));
const configureJobRejectionLetter = lazy(() =>
  import("@src/views/configure-job-rejection-letter")
);
const configureJobAdditionalFeatures = lazy(() =>
  import("@src/views/configure-job-additional-features")
);
const configureJobVideoContent = lazy(() =>
  import("@src/views/configure-job-video-content")
);
const ats = lazy(() => import("@src/views/ats"));
const atsDashboard = lazy(() => import("@src/views/ats-dashboard"));
const ApplicationTracker = lazy(() => import("@src/views/ApplicationTracker"));
const AdminCompanyPage = lazy(() => import("@src/views/AdminCompanyPage"));
const BrandColors = lazy(() => import("@src/views/BrandColors"));
const AdminPreview = lazy(() => import("@src/views/AdminPreview"));
const AdminPreviewExample = lazy(() =>
  import("@src/views/AdminPreviewExample")
);
const AdminTeamMember = lazy(() => import("@src/views/AdminTeamMember"));
const AtsDetail = lazy(() => import("@src/views/ats-detail"));
const AtsDetailWithLeftPanel = lazy(() =>
  import("@src/views/AtsDetailWithLeftPanel")
);
const IntroductoryVideo = lazy(() => import("@src/views/IntroductoryVideo"));
const submittedpage = lazy(() => import("@src/views/submittedpage"));
const WhatPositionYouInterested = lazy(() =>
  import("@src/views/WhatPositionYouInterested")
);
const ProfileSetup = lazy(() => import("@src/views/ProfileSetup"));
const ProfileSetupAdditionalInfo = lazy(() =>
  import("@src/views/ProfileSetupAdditionalInfo")
);
const ProfileSetupSecurity = lazy(() =>
  import("@src/views/ProfileSetupSecurity")
);
const ProfileSetupPreview = lazy(() =>
  import("@src/views/ProfileSetupPreview")
);
const AtsDashboardBtc = lazy(() => import("@src/views/AtsDashboardBtc"));
const searchJobs = lazy(() => import("@src/views/search-jobs"));
const searchJobsDetail = lazy(() => import("@src/views/search-jobs-detail"));
const searchJobPosting = lazy(() => import("@src/views/search-job-posting"));
const searchJobsCompanyDetail = lazy(() =>
  import("@src/views/search-jobs-company-detail")
);
const AtsSavedJobStepsOne = lazy(() =>
  import("@src/views/AtsSavedJobStepsOne")
);
const AtsSavedJobStepsOneUpload = lazy(() =>
  import("@src/views/AtsSavedJobStepsOneUpload")
);
const AtsSavedJobStepsCreate = lazy(() =>
  import("@src/views/AtsSavedJobStepsCreate")
);
const AtsSavedJobStepsCreateStepTwo = lazy(() =>
  import("@src/views/AtsSavedJobStepsCreateStepTwo")
);
const AtsSavedJobStepsCreateStepThree = lazy(() =>
  import("@src/views/AtsSavedJobStepsCreateStepThree")
);
const AtsSavedJobStepsCreateStepFour = lazy(() =>
  import("@src/views/AtsSavedJobStepsCreateStepFour")
);
const AtsSavedJobStepsCreateStepFive = lazy(() =>
  import("@src/views/AtsSavedJobStepsCreateStepFive")
);
const AtsSavedJobStepsScreeningQuest = lazy(() =>
  import("@src/views/AtsSavedJobStepsScreeningQuest")
);
const AtsSavedJobStepsSubmitted = lazy(() =>
  import("@src/views/AtsSavedJobStepsSubmitted")
);
const AtsDashboardBtcOverview = lazy(() =>
  import("@src/views/AtsDashboardBtcOverview")
);
const faqsB2C = lazy(() => import("@src/views/faqs-b2c"));
const allNotificationsB2C = lazy(() =>
  import("@src/views/all-notifications-b2c")
);
const dashboardB2C = lazy(() => import("@src/views/dashboard-b2c"));
const AuthenticationResent = lazy(() =>
  import("@src/views/AuthenticationResent")
);
const AuthenticationResentB2C = lazy(() =>
  import("@src/views/AuthenticationResent-b2c")
);
const ConfirmMailUpdate = lazy(() => import("@src/views/ConfirmMailUpdate"));
const ConfirmMailUpdateB2C = lazy(() =>
  import("@src/views/ConfirmMailUpdate-b2c")
);
const jobPosting = lazy(() => import("@src/views/job-posting"));
const CalendarB2b = lazy(() => import("@src/views/calendar-b2b"));
const MessagesB2b = lazy(() => import("@src/views/messages-b2b"));
const reviewB2b = lazy(() => import("@src/views/review-b2b"));
const reviewStep2 = lazy(() => import("@src/views/review-step-2"));
const reviewStep3 = lazy(() => import("@src/views/review-step-3"));
const submittedB2B = lazy(() => import("@src/views/submittedpage-b2b"));
const reviewB2C = lazy(() => import("@src/views/review-b2c"));
const reviewB2CStep2 = lazy(() => import("@src/views/review-b2c-step-2"));
const reviewB2CStep3 = lazy(() => import("@src/views/review-b2c-step-3"));
const reviewB2CStep4 = lazy(() => import("@src/views/review-b2c-step-4"));
const ShareAVideo = lazy(() => import("@src/views/ShareAVideo"));
const CreateVideoLoomIntegration = lazy(() =>
  import("@src/views/CreateVideoLoomIntegration")
);
const ShareVideoUploadOption = lazy(() =>
  import("@src/views/ShareVideoUploadOption")
);
const ShareVideoAddLink = lazy(() => import("@src/views/ShareVideoAddLink"));
const ContentLibrary = lazy(() => import("@src/views/content-library"));
const SharestoryContentLib = lazy(() =>
  import("@src/views/sharestory-content-lib")
);
const SharevideoContentLib = lazy(() =>
  import("@src/views/sharevideo-content-lib")
);
const SharevideoContentLibLoomInt = lazy(() =>
  import("@src/views/sharevideo-content-lib-loom-int")
);
const SharevideoContentLibUploadVideo = lazy(() =>
  import("@src/views/sharevideo-content-lib-upload-video")
);
const SharevideoContentLibVideoLink = lazy(() =>
  import("@src/views/sharevideo-content-lib-video-link")
);
const verifyEmail = lazy(() => import("@src/views/verify-email"));
const ContentLibraryPreview = lazy(() =>
  import("@src/views/content-library-preview")
);
const pricingPlans = lazy(() => import("@src/views/pricing-and-plans"));
const pricingPlansUpgrade = lazy(() =>
  import("@src/views/pricing-and-plans-upgrade")
);
const pricingOrderComplete = lazy(() =>
  import("@src/views/pricing-order-complete")
);
const pricingPlansB2B = lazy(() => import("@src/views/pricing-and-plans-b2b"));
const pricingPlansUpgradeB2B = lazy(() =>
  import("@src/views/pricing-and-plans-upgrade-b2b")
);
const pricingOrderCompleteB2B = lazy(() =>
  import("@src/views/pricing-order-complete-b2b")
);
const pricingPlansBillingB2B = lazy(() =>
  import("@src/views/pricing-and-plans-billing-b2b")
);
const UserPublicProfile = lazy(() => import("@src/views/user-public-profile"));
const DatabaseNetworking = lazy(() => import("@src/views/DatabaseNetworking"));
const ApplicantDatabase = lazy(() => import("@src/views/ApplicantDatabase"));
const ApplicantDatabaseNotFound = lazy(() =>
  import("@src/views/ApplicantDatabase-not-found")
);
const CreateEvent = lazy(() => import("@src/views/CreateEvent"));
const ThanksForInterested = lazy(() =>
  import("@src/views/ThanksForInterested")
);
const HomeB2c = lazy(() => import("@src/views/Home-b2c"));
const verifyEmailB2C = lazy(() => import("@src/views/verify-email-b2c"));
// const B2CHome = lazy(() => import('@src/views/b2c-home'))
const HomeB2B = lazy(() => import("@src/views/employer-home"));
const FaqB2B = lazy(() => import("@src/views/employer-faq"));
const ContactB2B = lazy(() => import("@src/views/employer-contact"));
const PrivacyB2B = lazy(() => import("@src/views/employer-privacy"));
const TermsB2B = lazy(() => import("@src/views/employer-terms"));

// Admin
const AdminSignIn = lazy(() => import("@src/admin/views/auth/SignIn"));
const AdminDashboard = lazy(() => import("@src/admin/views/Dashboard"));
// const AdminContactUsRequests = lazy(() => import('@src/admin/views/contactUsRequests/ContactUsRequests'))
const SizigiUsers = lazy(() =>
  import("@src/admin/views/sizigiUsers/SizigiUsers")
);
const AdminUsers = lazy(() => import("@src/admin/views/users/Users"));
const AdminImages = lazy(() => import("@src/admin/views/images/Images"));
const AdminBlogs = lazy(() => import("@src/admin/views/blogs/Blogs"));
const AdminPartners = lazy(() => import("@src/admin/views/partners/Partners"));
const AdminTestimonials = lazy(() =>
  import("@src/admin/views/testimonials/Testimonials")
);
const AdminSeoTags = lazy(() => import("@src/admin/views/seoTags/SeoTags"));
const AdminAddEditBlog = lazy(() =>
  import("@src/admin/views/blogs/addEditBlog")
);
const AdminPodcast = lazy(() => import("@src/admin/views/podcast/Podcast"));
const AdminPodcastForm = lazy(() =>
  import("@src/admin/views/podcast/PodcastForm")
);
const AdminVideos = lazy(() => import("@src/admin/views/videos/Videos"));

function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: "/admin/signin", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const title =
      "Sizigi Home | supercharging the job hunt | Job Search, Pitches & Analytics",
    description =
      "Your all-in-one tool for a supercharged job hunt! Organize your job search, create custom job pitches, and monitor how your applications are being viewed.",
    keyword = "sizigi,eportfolio,custom presentations,digital resume";
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <MetaTags>
            <title>
              {rest.metaData && rest.metaData.title
                ? rest.metaData.title
                : title}
            </title>
            <meta
              name="description"
              content={
                rest.metaData && rest.metaData.description
                  ? rest.metaData.description
                  : description
              }
            />
            <meta
              name="keywords"
              content={
                rest.metaData && rest.metaData.keywords
                  ? rest.metaData.keywords
                  : keyword
              }
            />
            {/* */}
            <meta
              property="og:title"
              content={
                rest.metaData && rest.metaData.title
                  ? rest.metaData.title
                  : title
              }
            />
            <meta
              itemProp="name"
              content={
                rest.metaData && rest.metaData.title
                  ? rest.metaData.title
                  : title
              }
            />
            <meta
              property="twitter:title"
              content={
                rest.metaData && rest.metaData.title
                  ? rest.metaData.title
                  : title
              }
            />
            {/* */}
            <meta
              property="og:description"
              content={
                rest.metaData && rest.metaData.description
                  ? rest.metaData.description
                  : description
              }
            />
            <meta
              itemProp="description"
              content={
                rest.metaData && rest.metaData.description
                  ? rest.metaData.description
                  : description
              }
            />
            <meta
              name="twitter:description"
              content={
                rest.metaData && rest.metaData.description
                  ? rest.metaData.description
                  : description
              }
            />
          </MetaTags>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      seoTags: [],
    };
  }

  UNSAFE_componentWillMount() {
    const instance = this;
    try {
      Api.webSeoTags({}, (err, result) => {
        if (err) instance.setState({ isLoading: false });
        else {
          instance.setState({ isLoading: false });
          if (result && result.data && result.data.seoTags) {
            instance.setState({ seoTags: result.data.seoTags });
          }
        }
      });
    } catch (e) {
      console.log(e);
      instance.setState({ isLoading: false });
    }
  }

  getMetaData = (pageName) => {
    const { seoTags } = this.state;
    let title = "",
      description = "",
      keywords = "";
    if (seoTags && seoTags.length) {
      const data = seoTags.find((tag) => tag.pageName === pageName);
      title = data && data.title ? data.title : "";
      description = data && data.description ? data.description : "";
      keywords =
        data && data.keyword && data.keyword.length > 0
          ? data.keyword.join()
          : "";
    }
    return { title, description, keywords };
  };

  render() {
    const { isLoading } = this.state;
    if (isLoading) return <Loader />;
    return (
      <Provider store={store}>
        <AppProvider>
          <>
            <ErrorBoundary>
              <Suspense fallback={<Loader />}>
                <Router>
                  <Switch>
                    {/*<Route exact path="/">
    									<Redirect to="/register" />
    								</Route>*/}
                    <AppRoute
                      exact
                      path="/"
                      layout={WebLayout}
                      component={Home}
                      metaData={this.getMetaData("Home Page")}
                    />
                    <AppRoute
                      exact
                      path="/partnership"
                      layout={WebLayout}
                      component={Partnership}
                      metaData={this.getMetaData("Partnership")}
                    />
                    <AppRoute
                      exact
                      path="/how-it-works"
                      layout={WebLayout}
                      component={Work}
                      metaData={this.getMetaData("How it works")}
                    />
                    <AppRoute
                      exact
                      path="/pricing"
                      layout={WebLayout}
                      component={Pricing}
                      metaData={this.getMetaData("Pricing")}
                    />
                    <AppRoute
                      exact
                      path="/joinnow"
                      layout={WebLayout}
                      component={joinnow}
                      metaData={this.getMetaData("joinnow")}
                    />
                    {/* <AppRoute exact path="/postajob" layout={WebLayout} component={postajob} metaData={this.getMetaData('postajob')} />
                    <AppRoute exact path="/buildaddresume" layout={WebLayout} component={buildaddresume} metaData={this.getMetaData('buildaddresume')} />
                    <AppRoute exact path="/AboutCompany" layout={WebLayout} component={AboutCompany} metaData={this.getMetaData('AboutCompany')} />
                    <AppRoute exact path="/AboutCompanystepone" layout={WebLayout} component={AboutCompanystepone} metaData={this.getMetaData('AboutCompanystepone')} />
                    <AppRoute exact path="/health-and-wellness" layout={WebLayout} component={HealthWellness} metaData={this.getMetaData('Health and Wellness')} />
                    <AppRoute exact path="/CreatePassword" layout={WebLayout} component={CreatePassword} metaData={this.getMetaData('CreatePassword')} />
                    <AppRoute exact path="/login" layout={WebLayout} component={login} metaData={this.getMetaData('login')} /> */}
                    <AppRoute
                      exact
                      path="/student-pricing"
                      layout={WebLayout}
                      component={StudentPricing}
                      metaData={this.getMetaData("Student Pricing")}
                    />
                    <AppRoute
                      exact
                      path="/why-sizigi"
                      layout={WebLayout}
                      component={WhySizigi}
                      metaData={this.getMetaData("Why Sizigi")}
                    />
                    <AppRoute
                      exact
                      path="/Contact"
                      layout={WebLayout}
                      component={Contact}
                      metaData={this.getMetaData("Contact us")}
                    />
                    <AppRoute
                      exact
                      path="/about"
                      layout={WebLayout}
                      component={About}
                      metaData={this.getMetaData("About us")}
                    />
                    <AppRoute
                      exact
                      path="/media"
                      layout={WebLayout}
                      component={Media}
                      metaData={this.getMetaData("Media")}
                    />
                    <AppRoute
                      exact
                      path="/blog/:slug"
                      layout={WebLayout}
                      component={Blog}
                    />
                    <AppRoute
                      exact
                      path="/podcast/:slug"
                      layout={WebLayout}
                      component={Podcast}
                    />
                    <AppRoute
                      exact
                      path="/privacy"
                      layout={WebLayout}
                      component={Privacy}
                      metaData={this.getMetaData("Privacy")}
                    />
                    <AppRoute
                      exact
                      path="/terms"
                      layout={WebLayout}
                      component={Terms}
                      metaData={this.getMetaData("Terms")}
                    />
                    <AppRoute
                      exact
                      path="/faq"
                      layout={WebLayout}
                      component={Faq}
                      metaData={this.getMetaData("FAQ")}
                    />
                    <AppRoute
                      exact
                      path="/partner-dice"
                      layout={WebLayout}
                      component={DiceAffiliate}
                      metaData={this.getMetaData("Dice Affiliate")}
                    />

                    <AppRoute
                      exact
                      path="/ForgetPassword"
                      layout={WebLayout}
                      component={ForgetPassword}
                      metaData={this.getMetaData("ForgetPassword")}
                    />
                    <AppRoute
                      exact
                      path="/Authentication"
                      layout={WebLayout}
                      component={Authentication}
                      metaData={this.getMetaData("Authentication")}
                    />
                    <AppRoute
                      exact
                      path="/ConfirmMail"
                      layout={WebLayout}
                      component={ConfirmMail}
                      metaData={this.getMetaData("ConfirmMail")}
                    />
                    <AppRoute
                      exact
                      path="/postajob-btc"
                      layout={WebLayout}
                      component={PostAJobBtc}
                      metaData={this.getMetaData("PostAJobBtc")}
                    />
                    <AppRoute
                      exact
                      path="/login-btc"
                      layout={WebLayout}
                      component={LoginBtc}
                      metaData={this.getMetaData("LoginBtc")}
                    />
                    <AppRoute
                      exact
                      path="/ForgotPassword-btc"
                      layout={WebLayout}
                      component={ForgotPasswordBtc}
                      metaData={this.getMetaData("ForgotPasswordBtc")}
                    />
                    <AppRoute
                      exact
                      path="/CreatePassword-btc"
                      layout={WebLayout}
                      component={CreatePasswordBtc}
                      metaData={this.getMetaData("CreatePasswordBtc")}
                    />
                    <AppRoute
                      exact
                      path="/Authentication-btc"
                      layout={WebLayout}
                      component={AuthenticationBtc}
                      metaData={this.getMetaData("AuthenticationBtc")}
                    />
                    <AppRoute
                      exact
                      path="/ConfirmMail-btc"
                      layout={WebLayout}
                      component={ConfirmMailBtc}
                      metaData={this.getMetaData("ConfirmMailBtc")}
                    />
                    <AppRoute
                      exact
                      path="/UploadCreateResume"
                      layout={WebLayout}
                      component={uploadcreate}
                      metaData={this.getMetaData("uploadcreate")}
                    />
                    <AppRoute
                      exact
                      path="/general-info"
                      layout={WebLayout}
                      component={generalinfo}
                      metaData={this.getMetaData("generalinfo")}
                    />
                    <AppRoute
                      exact
                      path="/job-details"
                      layout={WebLayout}
                      component={jobDetails}
                      metaData={this.getMetaData("jobDetails")}
                    />
                    <AppRoute
                      exact
                      path="/candidate-screening"
                      layout={WebLayout}
                      component={candidateScreening}
                      metaData={this.getMetaData("candidateScreening")}
                    />
                    <AppRoute
                      exact
                      path="/messages"
                      layout={WebLayout}
                      component={messages}
                      metaData={this.getMetaData("messages")}
                    />
                    <AppRoute
                      exact
                      path="/UploadResume"
                      layout={WebLayout}
                      component={UploadResume}
                      metaData={this.getMetaData("UploadResume")}
                    />
                    <AppRoute
                      exact
                      path="/CreateResumeStepOne"
                      layout={WebLayout}
                      component={CreateResumeStepOne}
                      metaData={this.getMetaData("CreateResumeStepOne")}
                    />
                    <AppRoute
                      exact
                      path="/CreateResumeStepTwo"
                      layout={WebLayout}
                      component={CreateResumeStepTwo}
                      metaData={this.getMetaData("CreateResumeStepTwo")}
                    />
                    <AppRoute
                      exact
                      path="/CreateResumeStepThree"
                      layout={WebLayout}
                      component={CreateResumeStepThree}
                      metaData={this.getMetaData("CreateResumeStepThree")}
                    />
                    <AppRoute
                      exact
                      path="/CreateResumeStepFour"
                      layout={WebLayout}
                      component={CreateResumeStepFour}
                      metaData={this.getMetaData("CreateResumeStepFour")}
                    />
                    <AppRoute
                      exact
                      path="/CreateResumeStepFive"
                      layout={WebLayout}
                      component={CreateResumeStepFive}
                      metaData={this.getMetaData("CreateResumeStepFive")}
                    />
                    <AppRoute
                      exact
                      path="/ScreeningQuestions"
                      layout={WebLayout}
                      component={ScreeningQuestions}
                      metaData={this.getMetaData("ScreeningQuestions")}
                    />
                    <AppRoute
                      exact
                      path="/calendar"
                      layout={WebLayout}
                      component={calendar}
                      metaData={this.getMetaData("calendar")}
                    />
                    <AppRoute
                      exact
                      path="/sharestory"
                      layout={WebLayout}
                      component={sharestory}
                      metaData={this.getMetaData("sharestory")}
                    />
                    <AppRoute
                      exact
                      path="/dashboard"
                      layout={WebLayout}
                      component={dashboard}
                      metaData={this.getMetaData("dashboard")}
                    />
                    <AppRoute
                      exact
                      path="/ApplicationTracker"
                      layout={WebLayout}
                      component={ApplicationTracker}
                      metaData={this.getMetaData("ApplicationTracker")}
                    />
                    <AppRoute
                      exact
                      path="/profile-personal-info"
                      layout={WebLayout}
                      component={profilePersonalInfo}
                      metaData={this.getMetaData("profilePersonalInfo")}
                    />
                    <AppRoute
                      exact
                      path="/profile-access-settings"
                      layout={WebLayout}
                      component={profileAccessSettings}
                      metaData={this.getMetaData("profileAccessSettings")}
                    />
                    <AppRoute
                      exact
                      path="/profile-change-password"
                      layout={WebLayout}
                      component={profileChangePassword}
                      metaData={this.getMetaData("profileChangePassword")}
                    />
                    <AppRoute
                      exact
                      path="/all-notifications"
                      layout={WebLayout}
                      component={allNotifications}
                      metaData={this.getMetaData("allNotifications")}
                    />
                    <AppRoute
                      exact
                      path="/faqs"
                      layout={WebLayout}
                      component={faqs}
                      metaData={this.getMetaData("faqs")}
                    />
                    <AppRoute
                      exact
                      path="/configure-job-rejection-letter"
                      layout={WebLayout}
                      component={configureJobRejectionLetter}
                      metaData={this.getMetaData("configureJobRejectionLetter")}
                    />
                    <AppRoute
                      exact
                      path="/configure-job-additional-features"
                      layout={WebLayout}
                      component={configureJobAdditionalFeatures}
                      metaData={this.getMetaData(
                        "configureJobAdditionalFeatures"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/configure-job-video-content"
                      layout={WebLayout}
                      component={configureJobVideoContent}
                      metaData={this.getMetaData("configureJobVideoContent")}
                    />
                    <AppRoute
                      exact
                      path="/ats"
                      layout={WebLayout}
                      component={ats}
                      metaData={this.getMetaData("ats")}
                    />
                    <AppRoute
                      exact
                      path="/ats-dashboard"
                      layout={WebLayout}
                      component={atsDashboard}
                      metaData={this.getMetaData("atsDashboard")}
                    />
                    <AppRoute
                      exact
                      path="/ats-detail"
                      layout={WebLayout}
                      component={AtsDetail}
                      metaData={this.getMetaData("AtsDetail")}
                    />
                    <AppRoute
                      exact
                      path="/AdminCompanyPage"
                      layout={WebLayout}
                      component={AdminCompanyPage}
                      metaData={this.getMetaData("AdminCompanyPage")}
                    />
                    <AppRoute
                      exact
                      path="/BrandColors"
                      layout={WebLayout}
                      component={BrandColors}
                      metaData={this.getMetaData("BrandColors")}
                    />
                    <AppRoute
                      exact
                      path="/AdminPreview"
                      layout={WebLayout}
                      component={AdminPreview}
                      metaData={this.getMetaData("AdminPreview")}
                    />
                    <AppRoute
                      exact
                      path="/AdminPreviewExample"
                      layout={WebLayout}
                      component={AdminPreviewExample}
                      metaData={this.getMetaData("AdminPreviewExample")}
                    />
                    <AppRoute
                      exact
                      path="/AdminTeamMember"
                      layout={WebLayout}
                      component={AdminTeamMember}
                      metaData={this.getMetaData("AdminTeamMember")}
                    />
                    <AppRoute
                      exact
                      path="/AtsDetail"
                      layout={WebLayout}
                      component={AtsDetail}
                      metaData={this.getMetaData("AtsDetail")}
                    />
                    <AppRoute
                      exact
                      path="/AtsDetailWithLeftPanel"
                      layout={WebLayout}
                      component={AtsDetailWithLeftPanel}
                      metaData={this.getMetaData("AtsDetailWithLeftPanel")}
                    />
                    <AppRoute
                      exact
                      path="/IntroductoryVideo"
                      layout={WebLayout}
                      component={IntroductoryVideo}
                      metaData={this.getMetaData("IntroductoryVideo")}
                    />
                    <AppRoute
                      exact
                      path="/submittedpage"
                      layout={WebLayout}
                      component={submittedpage}
                      metaData={this.getMetaData("submittedpage")}
                    />
                    <AppRoute
                      exact
                      path="/WhatPositionYouInterested"
                      layout={WebLayout}
                      component={WhatPositionYouInterested}
                      metaData={this.getMetaData("WhatPositionYouInterested")}
                    />
                    <AppRoute
                      exact
                      path="/ProfileSetup"
                      layout={WebLayout}
                      component={ProfileSetup}
                      metaData={this.getMetaData("ProfileSetup")}
                    />
                    <AppRoute
                      exact
                      path="/ProfileSetupAdditionalInfo"
                      layout={WebLayout}
                      component={ProfileSetupAdditionalInfo}
                      metaData={this.getMetaData("ProfileSetupAdditionalInfo")}
                    />
                    <AppRoute
                      exact
                      path="/ProfileSetupSecurity"
                      layout={WebLayout}
                      component={ProfileSetupSecurity}
                      metaData={this.getMetaData("ProfileSetupSecurity")}
                    />
                    <AppRoute
                      exact
                      path="/ProfileSetupPreview"
                      layout={WebLayout}
                      component={ProfileSetupPreview}
                      metaData={this.getMetaData("ProfileSetupPreview")}
                    />
                    <AppRoute
                      exact
                      path="/AtsDashboardBtc"
                      layout={WebLayout}
                      component={AtsDashboardBtc}
                      metaData={this.getMetaData("AtsDashboardBtc")}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsOne"
                      layout={WebLayout}
                      component={AtsSavedJobStepsOne}
                      metaData={this.getMetaData("AtsSavedJobStepsOne")}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsOneUpload"
                      layout={WebLayout}
                      component={AtsSavedJobStepsOneUpload}
                      metaData={this.getMetaData("AtsSavedJobStepsOneUpload")}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsCreate"
                      layout={WebLayout}
                      component={AtsSavedJobStepsCreate}
                      metaData={this.getMetaData("AtsSavedJobStepsCreate")}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsCreateStepTwo"
                      layout={WebLayout}
                      component={AtsSavedJobStepsCreateStepTwo}
                      metaData={this.getMetaData(
                        "AtsSavedJobStepsCreateStepTwo"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsCreateStepThree"
                      layout={WebLayout}
                      component={AtsSavedJobStepsCreateStepThree}
                      metaData={this.getMetaData(
                        "AtsSavedJobStepsCreateStepThree"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsCreateStepFour"
                      layout={WebLayout}
                      component={AtsSavedJobStepsCreateStepFour}
                      metaData={this.getMetaData(
                        "AtsSavedJobStepsCreateStepFour"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsCreateStepFive"
                      layout={WebLayout}
                      component={AtsSavedJobStepsCreateStepFive}
                      metaData={this.getMetaData(
                        "AtsSavedJobStepsCreateStepFive"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsScreeningQuest"
                      layout={WebLayout}
                      component={AtsSavedJobStepsScreeningQuest}
                      metaData={this.getMetaData(
                        "AtsSavedJobStepsScreeningQuest"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/AtsSavedJobStepsSubmitted"
                      layout={WebLayout}
                      component={AtsSavedJobStepsSubmitted}
                      metaData={this.getMetaData("AtsSavedJobStepsSubmitted")}
                    />
                    <AppRoute
                      exact
                      path="/AtsDashboardBtcOverview"
                      layout={WebLayout}
                      component={AtsDashboardBtcOverview}
                      metaData={this.getMetaData("AtsDashboardBtcOverview")}
                    />
                    <AppRoute
                      exact
                      path="/search-jobs"
                      layout={WebLayout}
                      component={searchJobs}
                      metaData={this.getMetaData("searchJobs")}
                    />
                    <AppRoute
                      exact
                      path="/search-jobs-detail"
                      layout={WebLayout}
                      component={searchJobsDetail}
                      metaData={this.getMetaData("searchJobsDetail")}
                    />
                    <AppRoute
                      exact
                      path="/search-job-posting"
                      layout={WebLayout}
                      component={searchJobPosting}
                      metaData={this.getMetaData("searchJobPosting")}
                    />
                    <AppRoute
                      exact
                      path="/search-jobs-company-detail"
                      layout={WebLayout}
                      component={searchJobsCompanyDetail}
                      metaData={this.getMetaData("searchJobsCompanyDetail")}
                    />
                    <AppRoute
                      exact
                      path="/faqs-b2c"
                      layout={WebLayout}
                      component={faqsB2C}
                      metaData={this.getMetaData("faqsB2C")}
                    />
                    <AppRoute
                      exact
                      path="/all-notifications-b2c"
                      layout={WebLayout}
                      component={allNotificationsB2C}
                      metaData={this.getMetaData("allNotificationsB2C")}
                    />
                    <AppRoute
                      exact
                      path="/dashboard-b2c"
                      layout={WebLayout}
                      component={dashboardB2C}
                      metaData={this.getMetaData("dashboardB2C")}
                    />
                    <AppRoute
                      exact
                      path="/AuthenticationResent"
                      layout={WebLayout}
                      component={AuthenticationResent}
                      metaData={this.getMetaData("AuthenticationResent")}
                    />
                    <AppRoute
                      exact
                      path="/AuthenticationResent-b2c"
                      layout={WebLayout}
                      component={AuthenticationResentB2C}
                      metaData={this.getMetaData("AuthenticationResentB2C")}
                    />
                    <AppRoute
                      exact
                      path="/ConfirmMailUpdate"
                      layout={WebLayout}
                      component={ConfirmMailUpdate}
                      metaData={this.getMetaData("ConfirmMailUpdate")}
                    />
                    <AppRoute
                      exact
                      path="/ConfirmMailUpdate-b2c"
                      layout={WebLayout}
                      component={ConfirmMailUpdateB2C}
                      metaData={this.getMetaData("ConfirmMailUpdateB2C")}
                    />
                    <AppRoute
                      exact
                      path="/calendar-b2b"
                      layout={WebLayout}
                      component={CalendarB2b}
                      metaData={this.getMetaData("CalendarB2b")}
                    />
                    <AppRoute
                      exact
                      path="/messages-b2b"
                      layout={WebLayout}
                      component={MessagesB2b}
                      metaData={this.getMetaData("MessagesB2b")}
                    />
                    <AppRoute
                      exact
                      path="/job-posting"
                      layout={WebLayout}
                      component={jobPosting}
                      metaData={this.getMetaData("jobPosting")}
                    />
                    <AppRoute
                      exact
                      path="/review-b2b"
                      layout={WebLayout}
                      component={reviewB2b}
                      metaData={this.getMetaData("reviewB2b")}
                    />
                    <AppRoute
                      exact
                      path="/review-step-2"
                      layout={WebLayout}
                      component={reviewStep2}
                      metaData={this.getMetaData("reviewStep2")}
                    />
                    <AppRoute
                      exact
                      path="/review-step-3"
                      layout={WebLayout}
                      component={reviewStep3}
                      metaData={this.getMetaData("reviewStep3")}
                    />
                    <AppRoute
                      exact
                      path="/submittedpage-b2b"
                      layout={WebLayout}
                      component={submittedB2B}
                      metaData={this.getMetaData("submittedB2B")}
                    />
                    <AppRoute
                      exact
                      path="/review-b2c"
                      layout={WebLayout}
                      component={reviewB2C}
                      metaData={this.getMetaData("reviewB2C")}
                    />
                    <AppRoute
                      exact
                      path="/review-b2c-step-2"
                      layout={WebLayout}
                      component={reviewB2CStep2}
                      metaData={this.getMetaData("reviewB2CStep2")}
                    />
                    <AppRoute
                      exact
                      path="/review-b2c-step-3"
                      layout={WebLayout}
                      component={reviewB2CStep3}
                      metaData={this.getMetaData("reviewB2CStep3")}
                    />
                    <AppRoute
                      exact
                      path="/review-b2c-step-4"
                      layout={WebLayout}
                      component={reviewB2CStep4}
                      metaData={this.getMetaData("reviewB2CStep4")}
                    />
                    <AppRoute
                      exact
                      path="/CreateVideoLoomIntegration"
                      layout={WebLayout}
                      component={CreateVideoLoomIntegration}
                      metaData={this.getMetaData("CreateVideoLoomIntegration")}
                    />
                    <AppRoute
                      exact
                      path="/ContentLibrary"
                      layout={WebLayout}
                      component={ContentLibrary}
                      metaData={this.getMetaData("ContentLibrary")}
                    />
                    <AppRoute
                      exact
                      path="/sharestory-content-lib"
                      layout={WebLayout}
                      component={SharestoryContentLib}
                      metaData={this.getMetaData("SharestoryContentLib")}
                    />
                    <AppRoute
                      exact
                      path="/sharevideo-content-lib"
                      layout={WebLayout}
                      component={SharevideoContentLib}
                      metaData={this.getMetaData("SharevideoContentLib")}
                    />
                    <AppRoute
                      exact
                      path="/sharevideo-content-lib-loom-int"
                      layout={WebLayout}
                      component={SharevideoContentLibLoomInt}
                      metaData={this.getMetaData("SharevideoContentLibLoomInt")}
                    />
                    <AppRoute
                      exact
                      path="/sharevideo-content-lib-upload-video"
                      layout={WebLayout}
                      component={SharevideoContentLibUploadVideo}
                      metaData={this.getMetaData(
                        "SharevideoContentLibUploadVideo"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/sharevideo-content-lib-video-link"
                      layout={WebLayout}
                      component={SharevideoContentLibVideoLink}
                      metaData={this.getMetaData(
                        "SharevideoContentLibVideoLink"
                      )}
                    />
                    <AppRoute
                      exact
                      path="/content-library-preview"
                      layout={WebLayout}
                      component={ContentLibraryPreview}
                      metaData={this.getMetaData("ContentLibraryPreview")}
                    />
                    <AppRoute
                      exact
                      path="/UserPublicProfile"
                      layout={WebLayout}
                      component={UserPublicProfile}
                      metaData={this.getMetaData("UserPublicProfile")}
                    />
                    <AppRoute
                      exact
                      path="/ApplicantDatabase"
                      layout={WebLayout}
                      component={ApplicantDatabase}
                      metaData={this.getMetaData("ApplicantDatabase")}
                    />
                    <AppRoute
                      exact
                      path="/ApplicantDatabase-not-found"
                      layout={WebLayout}
                      component={ApplicantDatabaseNotFound}
                      metaData={this.getMetaData("ApplicantDatabaseNotFound")}
                    />
                    <AppRoute
                      exact
                      path="/HomeB2c"
                      layout={WebLayout}
                      component={HomeB2c}
                      metaData={this.getMetaData("HomeB2c")}
                    />
                    <AppRoute
                      exact
                      path="/CreateEvent"
                      layout={WebLayout}
                      component={CreateEvent}
                      metaData={this.getMetaData("CreateEvent")}
                    />
                    <AppRoute
                      exact
                      path="/ThanksForInterested"
                      layout={WebLayout}
                      component={ThanksForInterested}
                      metaData={this.getMetaData("ThanksForInterested")}
                    />
                    <AppRoute
                      exact
                      path="/CreateVideoLoomIntegration"
                      layout={WebLayout}
                      component={CreateVideoLoomIntegration}
                      metaData={this.getMetaData("CreateVideoLoomIntegration")}
                    />
                    <AppRoute
                      exact
                      path="/CreateVideoLoomIntegration"
                      layout={WebLayout}
                      component={CreateVideoLoomIntegration}
                      metaData={this.getMetaData("CreateVideoLoomIntegration")}
                    />
                    <AppRoute
                      exact
                      path="/ShareAVideo"
                      layout={WebLayout}
                      component={ShareAVideo}
                      metaData={this.getMetaData("ShareAVideo")}
                    />
                    <AppRoute
                      exact
                      path="/ShareVideoUploadOption"
                      layout={WebLayout}
                      component={ShareVideoUploadOption}
                      metaData={this.getMetaData("ShareVideoUploadOption")}
                    />
                    <AppRoute
                      exact
                      path="/ShareVideoAddLink"
                      layout={WebLayout}
                      component={ShareVideoAddLink}
                      metaData={this.getMetaData("ShareVideoAddLink")}
                    />
                    <AppRoute
                      exact
                      path="/verify-email"
                      layout={WebLayout}
                      component={verifyEmail}
                      metaData={this.getMetaData("verifyEmail")}
                    />
                    <AppRoute
                      exact
                      path="/verify-email-b2c"
                      layout={WebLayout}
                      component={verifyEmailB2C}
                      metaData={this.getMetaData("verifyEmailB2C")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-and-plans"
                      layout={WebLayout}
                      component={pricingPlans}
                      metaData={this.getMetaData("pricingPlans")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-and-plans-upgrade"
                      layout={WebLayout}
                      component={pricingPlansUpgrade}
                      metaData={this.getMetaData("pricingPlansUpgrade")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-order-complete"
                      layout={WebLayout}
                      component={pricingOrderComplete}
                      metaData={this.getMetaData("pricingOrderComplete")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-and-plans-b2b"
                      layout={WebLayout}
                      component={pricingPlansB2B}
                      metaData={this.getMetaData("pricingPlansB2B")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-and-plans-upgrade-b2b"
                      layout={WebLayout}
                      component={pricingPlansUpgradeB2B}
                      metaData={this.getMetaData("pricingPlansUpgradeB2B")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-order-complete-b2b"
                      layout={WebLayout}
                      component={pricingOrderCompleteB2B}
                      metaData={this.getMetaData("pricingOrderCompleteB2B")}
                    />
                    <AppRoute
                      exact
                      path="/pricing-and-plans-billing-b2b"
                      layout={WebLayout}
                      component={pricingPlansBillingB2B}
                      metaData={this.getMetaData("pricingPlansBillingB2B")}
                    />
                    <AppRoute
                      exact
                      path="/DatabaseNetworking"
                      layout={WebLayout}
                      component={DatabaseNetworking}
                      metaData={this.getMetaData("DatabaseNetworking")}
                    />
                    {/* <AppRoute exact path="/b2c-home" layout={WebLayout} component={B2CHome} metaData={this.getMetaData('B2CHome')} /> */}
                    <AppRoute
                      exact
                      path="/employer"
                      layout={WebLayout}
                      component={HomeB2B}
                      metaData={this.getMetaData("Home")}
                    />
                    <AppRoute
                      exact
                      path="/employer-faq"
                      layout={WebLayout}
                      component={FaqB2B}
                      metaData={this.getMetaData("Faq")}
                    />
                    <AppRoute
                      exact
                      path="/employer-contact"
                      layout={WebLayout}
                      component={ContactB2B}
                      metaData={this.getMetaData("Contact us")}
                    />
                    <AppRoute
                      exact
                      path="/employer-privacy"
                      layout={WebLayout}
                      component={PrivacyB2B}
                      metaData={this.getMetaData("Privacy")}
                    />
                    <AppRoute
                      exact
                      path="/employer-terms"
                      layout={WebLayout}
                      component={TermsB2B}
                      metaData={this.getMetaData("Terms")}
                    />

                    <Route exact path="/admin">
                      <Redirect to="/admin/signin" />
                    </Route>
                    <AppRoute
                      exact
                      path="/admin/signin"
                      layout={BlankLayout}
                      component={AdminSignIn}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/dashboard"
                      layout={AdminLayout}
                      component={AdminDashboard}
                    />
                    {/*<PrivateRoute exact path="/admin/contact-us-requests" layout={AdminLayout} component={AdminContactUsRequests} />*/}
                    <PrivateRoute
                      exact
                      path="/admin/sizigi-users"
                      layout={AdminLayout}
                      component={SizigiUsers}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/users"
                      layout={AdminLayout}
                      component={AdminUsers}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/images"
                      layout={AdminLayout}
                      component={AdminImages}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/blogs"
                      layout={AdminLayout}
                      component={AdminBlogs}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/add-edit-blog/:slug?"
                      layout={AdminLayout}
                      component={AdminAddEditBlog}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/podcasts"
                      layout={AdminLayout}
                      component={AdminPodcast}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/podcast/:slug?"
                      layout={AdminLayout}
                      component={AdminPodcastForm}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/partners"
                      layout={AdminLayout}
                      component={AdminPartners}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/testimonials"
                      layout={AdminLayout}
                      component={AdminTestimonials}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/seo-tags"
                      layout={AdminLayout}
                      component={AdminSeoTags}
                    />
                    <PrivateRoute
                      exact
                      path="/admin/videos"
                      layout={AdminLayout}
                      component={AdminVideos}
                    />

                    <Route path="*" component={NotFound} />
                  </Switch>
                </Router>
              </Suspense>
            </ErrorBoundary>
          </>
        </AppProvider>
      </Provider>
    );
  }
}

export default App;
