import React from 'react'
import GlobalStyles from '@src/admin/assets/styles/globalStyle'

class BlankLayout extends React.Component {
	render() {
		return (
			<div id="adminMain">
				<GlobalStyles />
				{this.props.children}
			</div>
		)
	}
}

export default BlankLayout
