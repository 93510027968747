import React from 'react'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'

class WebLayout extends React.Component {
	componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

	render() {
		const currentPath = this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
		return (
			<div id="appMain">
				{currentPath !== '/partner-dice' && <Header {...this.props} />}
				<div className="wrapper">
					{this.props.children}
				</div>
				<Footer {...this.props} />
			</div>
		)
	}
}

export default WebLayout
