import { all, takeEvery, put /*, call*/ } from 'redux-saga/effects';
import actions from './actions';
// import api from '@src/lib/services/api'
import fakeData from './fakeData';
const imageList = fakeData;

function* loadImages() {
  try {
    // let data = yield call(api.fetchImageList);
    yield put(actions.loadImagesSuccess(imageList));
  } catch (error) {
    yield put(actions.loadImagesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_IMAGES, loadImages)
  ]);
}
