import { combineReducers } from 'redux'
import App from '@src/redux/app/reducer'
import Auth from '@src/redux/auth/reducer'
import ThemeSwitcher from '@src/redux/themeSwitcher/reducer'
import LanguageSwitcher from '@src/redux/languageSwitcher/reducer'
import Ecommerce from '@src/redux/ecommerce/reducer'
import ContactUsRequests from '@src/redux/contactUsRequests/reducers';
import Images from '@src/redux/images/reducers';
import Blogs from '@src/redux/blogs/reducers';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Ecommerce,
  ContactUsRequests,
  Images,
  Blogs
});
