const actions = {
  LOAD_IMAGES: 'LOAD_IMAGES',
  LOAD_IMAGES_SUCCESS: 'LOAD_IMAGES_SUCCESS',
  LOAD_IMAGES_ERROR: 'LOAD_IMAGES_ERROR',
  TOGGLE_IMAGE_MODAL: 'TOGGLE_IMAGE_MODAL',

  loadImages: () => {
    return { type: actions.LOAD_IMAGES };
  },

  loadImagesSuccess: data => ({
    type: actions.LOAD_IMAGES_SUCCESS,
    payload: { data },
  }),

  loadImagesError: error => ({
    type: actions.LOAD_IMAGES_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_IMAGE_MODAL,
    payload: { data },
  })
};
export default actions;
