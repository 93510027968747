import React from 'react'
import toastr from 'toastr'
import Api from '@src/lib/services/api';

class NewsLetter extends React.Component {
	constructor () {
		super()
		this.state = {
			email: '',
			loader: false
		}
	}

	handleSubmit = (event) => {
		event.preventDefault()
		const { loader, email } = this.state
		if (email && !loader) {
			this.setState({ loader: true })
			Api.addContact({email}, (err, result) => {
				this.setState({ loader: false, email: '' })
				if (err) return toastr.error('Unable to received subscription')
				else return toastr.success('Your subscription saved successfully!')
			})
		}
	}

	render() {
		const { email, loader } = this.state
		return (
			<div id="newsletterForm" className="py-3">
        <div className="pb-1">Sign up for our newsletter</div>
        <form className="input-group my-1" onSubmit={(event) => this.handleSubmit(event)}>
          <input type="email" className="form-control" value={email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Enter your email" aria-label="Enter your email" aria-describedby="btnNewsletter" required />
          <div className="input-group-append">
            <button type="submit" id="btnNewsletter" disabled={loader} className="btn btn-mt-secondary mt-hover btn-mt-md input-group-text justify-content-center">Sign up</button>
          </div>
        </form>
      </div>
		)
	}
}

export default NewsLetter
